import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import marked from "marked"
import React, { useEffect, useRef, useState } from "react"
import { Container, Spinner } from "react-bootstrap"
import Helmet from "react-helmet"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Moment from "react-moment"
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import Sticky from "react-sticky-el"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

import Layout from "../components/layout"

import modalImg2 from "../images/new-images/case-studies.jpg"
import person from "../images/person-holder.jpg"
import "./news.css"

const PostContent = loadable(() =>
  pMinDelay(import("../components/blog-template/post-content"), 500)
)
const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))
const ExitIntentBlog = loadable(() =>
  pMinDelay(import("../components/exit-intent/exit-intent-blog"), 500)
)
const ExitIntentModalQuickbase = loadable(() =>
  pMinDelay(import("../components/modal/exit-intent-quickbase-modal"), 500)
)
const PostPage = loadable(() =>
  pMinDelay(import("../components/blog-template/post-page"), 500)
)
// import DOMPurify from "dompurify"
const slugify = require("slugify")

//  require
// var MarkdownIt = require("markdown-it")
// var remark = require("remark")
// var externalLinks = require("remark-external-links")
// var html = require("remark-html")

const BlogTemplate = ({ data, pageContext, location, datas }) => {
  // const [mkd, setMkd] = React.useState()
  // const markdownIt = new MarkdownIt({
  //   html: true,
  // })
  // DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  //   if (data.tagName === "iframe") {
  //     const src = node.getAttribute("src") || ""
  //     if (!src.startsWith("https://www.youtube.com/embed/")) {
  //       return node.parentNode?.removeChild(node)
  //     }
  //   }
  // })
  // const [mkdtwo, setMkdtwo] = React.useState()
  const { post, allPost } = data

  //// below: for mobile infite scroll
  const mobileDeviceSize = 480
  const pageWidth = typeof window !== "undefined" && window.innerWidth
  const afterBlogsSec =
    typeof document !== "undefined"
      ? document.querySelectorAll(
          "#quick-base-free-trial, .site-footer, #copyrights"
        )
      : []
  const [isResizeListenerEnabled, setIsResizeListenerEnabled] = useState(false)
  const [currWidth, setCurrWidth] = useState(0)
  const [currentItemsMobile, setCurrentItemsMobile] = useState(null)
  const [postCount, setPostCount] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [isMobileDevice, setIsMobileDevice] = useState(pageWidth <= 480)
  const containerRef = useRef(null)
  const handleWidthResize = width => {
    if (width <= mobileDeviceSize) {
      setIsMobileDevice(true)
    } else {
      setIsMobileDevice(false)
    }
    setCurrWidth(width)
  }
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
    delay: 400,
  }
  const cbObserver = async entries => {
    const [entry] = entries
    const currPosts = allPost.edges
    const count = postCount + 1
    const sliceAllItems = currPosts.slice(0, count)
    ;[].forEach.call(afterBlogsSec, function(el) {
      el.classList.remove("d-none")
    })
    if (count <= currPosts.length) {
      ;[].forEach.call(afterBlogsSec, function(el) {
        el.classList.add("d-none")
      })
    }
    if (currPosts.length < count) return
    setIsVisible(entry.isIntersecting)
    setCurrentItemsMobile(sliceAllItems)
    if (isVisible) {
      setPostCount(count)
      setIsVisible(false)
    }
  }
  //// above for mobile infite scroll

  const category = post.categories.length > 0 ? post.categories[0].title : ""

  useEffect(() => {
    setTimeout(() => {
      if (!isResizeListenerEnabled) {
        setIsResizeListenerEnabled(true)
        if (typeof window !== "undefined") {
          window.addEventListener("resize", () =>
            handleWidthResize(window.innerWidth)
          )
        }
      }

      if (pageWidth > mobileDeviceSize) {
        setPostCount(0)
        ;[].forEach.call(afterBlogsSec, function(el) {
          el.classList.remove("d-none")
        })
      }
      if (pageWidth <= mobileDeviceSize) {
        const currPosts = allPost.edges
        const count = postCount + 1
        const observer = new IntersectionObserver(cbObserver, options)
        const unobserveRef = () => {
          if (containerRef.current) observer.unobserve(containerRef.current)
        }

        ;[].forEach.call(afterBlogsSec, function(el) {
          el.classList.add("d-none")
        })

        if (containerRef.current) observer.observe(containerRef.current)

        if (currPosts.length < count) return () => unobserveRef()
        setCurrentItemsMobile(currPosts.slice(0, count))
        if (isVisible) {
          setPostCount(count)
          setIsVisible(false)
        }

        return () => unobserveRef()
      }
    }, 5000)
  }, [
    isVisible,
    postCount,
    currWidth,
    cbObserver,
    allPost.edges,
    pageWidth,
    isResizeListenerEnabled,
    options,
  ])

  const limit = 10
  let recentPostCount = 0

  // console.log(post.custom_template)
  return (
    <Layout location={location}>
      <Helmet>
        <script>
          {`setTimeout(() => {
            var links = document.querySelectorAll('.post-content a');  
            for (var i = 0, length = links.length; i < length; i++) {  
            if (links[i].hostname != window.location.hostname) {
                links[i].target = '_blank';
                links[i].rel = 'noopener noreferrer';
              }
            }
          },5000)`}
        </script>
      </Helmet>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{post.metaTitle ? post.metaTitle : post.title}</title>
        <meta name="keywords" content={post.metakeywords} />
        <meta name="description" content={post.metaDescription} />
        <meta
          property="og:image"
          content={post.mainImage.asset.gatsbyImageData.images.fallback.src}
        />
        <meta
          property="og:image:secure_url"
          content={post.mainImage.asset.gatsbyImageData.images.fallback.src}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="515" />
        <meta
          property="og:image:alt"
          content="A shiny red apple with a bite taken out"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@quandarycg" />
        <meta name="twitter:creator" content="@quandarycg" />
        <meta
          name="twitter:image"
          content={post.mainImage.asset.gatsbyImageData.images.fallback.src}
        />
      </Helmet>
      {post.title === "Your Expert Guide on Invoice Automation" ? (
        <ExitIntentBlog
          btnLink="/case-studies"
          buttonLabel="Explore Case Studies"
          heading="Stop Processing Invoices Manually!"
          subHeading="Discover how low code applications can drastically reduce your invoice processing costs."
          modalImg={modalImg2}
          surveyKey="proc2Modal"
        />
      ) : // : category !== "Procurement" ? (
      //   <ExitIntentBlog
      //     btnLink="/the-procurement-management-strategic-playbook/"
      //     buttonLabel="Read Guide"
      //     heading="Inefficient Procurement Processes Put YOU at Risk"
      //     subHeading="Start reducing those risks today using our actionable guide!"
      //     modalImg={modalImg}
      //     surveyKey="procModal"
      //   />
      // ) : category === "Procurement" ? (
      //   <ExitIntentBlog2
      //     btnLink="/the-procurement-management-strategic-playbook/"
      //     buttonLabel="Read Guide"
      //     heading="Inefficient Procurement Processes Put YOU at Risk"
      //     subHeading="Start reducing those risks today using our actionable guide!"
      //     modalImg={modalImg}
      //     surveyKey="procModal"
      //   />
      // )

      null}

      {/* {category === "Citizen Development" ? <ExitIntentModal /> : null} */}
      {category === "Quickbase" ? <ExitIntentModalQuickbase /> : null}
      <div className="page-content blog-single-page">
        <Container>
          <div className="row justify-content-center">
            <div className="col-lg-9 pr-md-0">
              <div className="blog-content-wrapper">
                <div className="blog-single-content">
                  <div className="title-post-meta">
                    <Link to={`/category/${slugify(category.toLowerCase())}/`}>
                      {category}
                    </Link>
                    <GatsbyImage
                      placeholder="blurred"
                      image={
                        post.mainImage
                          ? post.mainImage.asset.gatsbyImageData
                          : ""
                      }
                      className="img-fluid"
                      layout="constrained"
                      style={{
                        height: "48px",
                        width: "48px",
                        visibility: "hidden",
                      }}
                    />
                  </div>

                  <h1 className="font-weight-bold">{post.title}</h1>

                  <div className="d-flex align-items-center title-post-meta">
                    <div className="entry-author-image d-inline mr-2">
                      <GatsbyImage
                        image={
                          post.author.image
                            ? post.author.image.asset.gatsbyImageData
                            : person
                        }
                        className="img-fluid "
                        layout="constrained"
                        alt={post.author.name}
                        style={{ height: "48px", width: "48px" }}
                      />
                    </div>
                    <div>
                      <span>by</span>&nbsp;
                      <Link
                        to={`/author/${slugify(
                          post.author.name.toLowerCase()
                        )}/`}
                        title={`Posts by ${post.author.name}`}
                        rel="author"
                        data-wpel-link="internal"
                      >
                        {post.author.name}
                      </Link>
                      <span> on </span>
                      <Moment
                        add={{ days: 1, hours: 9 }}
                        format="MMMM DD, YYYY"
                      >
                        {post.publishedAt !== null
                          ? post.publishedAt
                          : post._createdAt}
                      </Moment>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      {" "}
                      <ul className="list-unstyled d-flex">
                        <li className="mb-2 mr-2">
                          <FacebookShareButton
                            url={`https://quandarycg.com/${post.slug.current}`}
                          >
                            <FacebookIcon size={40} borderRadius={5} />
                          </FacebookShareButton>
                        </li>
                        <li className="mb-2 mx-2">
                          <TwitterShareButton
                            url={`https://quandarycg.com/${post.slug.current}`}
                          >
                            <TwitterIcon size={40} borderRadius={5} />
                          </TwitterShareButton>
                        </li>
                        <li className="mb-2 mx-2">
                          <LinkedinShareButton
                            url={`https://quandarycg.com/${post.slug.current}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <LinkedinIcon size={40} borderRadius={5} />
                          </LinkedinShareButton>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="py-3 banner_img">
                    <GatsbyImage
                      placeholder="blurred"
                      image={
                        post.mainImage
                          ? post.mainImage.asset.gatsbyImageData
                          : ""
                      }
                      // imgClassName="img-fluid"
                      className="img-fluid w-100"
                      alt="banner"
                      layout="constrained"
                      style={{
                        width: "885px",
                        height: "590px",
                      }}
                    />
                  </div>
                  <PostPage post={post} />
                </div>
              </div>
            </div>

            <div className="block col-lg-3" style={{ paddingRight: "0px" }}>
              <div className="blog-single-content-sidebar pr-lg-0">
                <Sticky
                  boundaryElement=".block"
                  hideOnBoundaryHit={true}
                  topOffset={50}
                >
                  {/* <div className="block my-4 email-signup">
                    <div className="intro mb-3">
                      <div className="row">
                        <div className="col-md-12">
                          <h4 className="mt-0 font-weight-medium">
                            Sign up to our newsletter:
                          </h4>
                        
                        </div>
                      </div>
                    </div>
                    <WebriQForm
                      id="contact-form"
                      name="Email Sign Up Form"
                      className="contactForm"
                      data-form-id="5f147983-dcbb-4948-895c-af22ed7934ee"
                      data-thankyou-url="/thank-you"
                    >
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <input
                            type="email"
                            name="Email"
                            size={40}
                            className="form-control"
                            placeholder="Email Here"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="form-group col-12 d-flex justify-content-center captcha-wrap">
                          <div className="webriq-recaptcha" />
                        </div>
                        <div className="form-group col-12 text-center">
                          <div className="input-filled">
                            <button
                              type="submit"
                              className="btn btn-primary btn-arrow"
                            >
                              Sign Up Now!
                            </button>
                          </div>
                        </div>
                      </div>
                    </WebriQForm>
                  </div> */}
                  <div className="recent-post-sidebar">
                    <ul className="list-unstyled">
                      <li className="sideline-category-head">
                        <b>{category}</b>
                        <p>Resources</p>
                      </li>

                      {(() => {
                        if (category === "BTaaS") {
                          return (
                            <li>
                              <Link
                                className="sideline-category-title"
                                to={
                                  "/process-improvement-cut-waste-and-improve-business-efficiency/"
                                }
                              >
                                <p>The Process Improvement Guide</p>
                              </Link>
                            </li>
                          )
                        } else if (category === "Procurement") {
                          return (
                            <li>
                              <Link
                                className="sideline-category-title"
                                to={
                                  "/the-procurement-management-strategic-playbook/"
                                }
                              >
                                <p>The Procurement Management Playbook</p>
                              </Link>
                            </li>
                          )
                        } else {
                          return <span></span>
                        }
                      })()}
                      {data.allPost.edges.map(({ node }) => {
                        if (recentPostCount >= limit) return
                        if (
                          node.categories.length > 0 &&
                          node.categories[0].title === category
                        )
                          recentPostCount = recentPostCount + 1
                        return (
                          <>
                            {node.categories.length > 0 &&
                              node.categories[0].title === category && (
                                <li>
                                  <Link
                                    className="sideline-category-title"
                                    to={`/${node.slug.current}/`}
                                  >
                                    {node.title}
                                  </Link>
                                </li>
                              )}
                          </>
                        )
                      })}
                    </ul>
                  </div>
                </Sticky>
              </div>
            </div>
          </div>
          {isMobileDevice && currentItemsMobile
            ? currentItemsMobile.map(({ node, i }) => {
                if (node.slug.current !== post.slug.current) {
                  return <PostContent post={node} key={i} />
                }
              })
            : ""}
          <div
            ref={containerRef}
            className="d-flex justify-content-center mb-3"
          >
            {pageWidth <= 480 &&
            currentItemsMobile &&
            currentItemsMobile.length > postCount ? (
              <Spinner animation="border" variant="primary" />
            ) : (
              ""
            )}
          </div>
        </Container>

        <LazyLoadComponent>
          <div id="quick-base-free-trial" className="mt-5">
            {/* CASE STUDIES SECTION */}
            <section id="case-studies-container">
              <div id="case-studies" className="bg-indigo">
                <div className="container">
                  <div className="intro text-center">
                    <div className="row">
                      <div className="col-md-10 mx-auto">
                        <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                          Case Studies
                        </h5>
                        <h2 className=" my-2 text-white">
                          We Save Clients 1000s of Hours. Every Year.
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Suspense fallback={<div>Loading...</div>}> */}
                <Cases allSanityCaseStudy={data.allSanityCaseStudy} />
                {/* </Suspense> */}
                <div className="text-center">
                  {/* <p className="text-white mt-5 col-8 col-lg-6 m-auto pt-5">
                    Aren’t you tired of struggling to stay ahead? Stop the
                    growing waste and frustration with better systems. We’ve
                    helped clients achieve incredible results with custom
                    applications and integrations built using low code.
                  </p> */}
                  <Link
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                    to="/case-studies/"
                  >
                    More Case Studies
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </LazyLoadComponent>
      </div>
    </Layout>
  )
}

export default BlogTemplate

export const blogQuery = graphql`
  query BlogPostTemplateQuery($slug: String!) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      title
      metaTitle
      metaDescription
      tag {
        id
        title
      }
      mainImage {
        asset {
          gatsbyImageData(
            aspectRatio: 1.91
            outputPixelDensities: 1.5
            width: 1200
            fit: FILL
            formats: JPG
            placeholder: BLURRED
          )
          url
        }
      }
      custom_template
      publishedAt
      _createdAt
      body
      shortDescription
      title
      slug {
        current
      }
      categories {
        title
      }
      author {
        id
        name
        slug {
          current
        }
        image {
          asset {
            gatsbyImageData(
              fit: FILL
              placeholder: BLURRED
              sizes: ""
              layout: CONSTRAINED
              width: 48
              height: 48
            )
          }
        }
      }
    }
    allPost: allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          title
          metaTitle
          metaDescription
          tag {
            id
            title
          }
          mainImage {
            asset {
              gatsbyImageData(
                width: 885
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
              url
            }
          }
          custom_template
          publishedAt
          _createdAt
          body
          shortDescription
          title
          slug {
            current
          }
          categories {
            title
          }
          author {
            id
            name
            slug {
              current
            }
            image {
              asset {
                gatsbyImageData(
                  fit: FILL
                  placeholder: BLURRED
                  sizes: ""
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
          metaTitle
          metaDescription
        }
      }
    }
  }
`
